<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        Write the balanced molecular, total ionic, and net ionic equation for the chemical reaction
        described below.
      </p>

      <p class="pl-10 mb-6">
        <stemble-latex
          content="$\text{Aqueous strontium nitrate plus aqueous potassium sulfate}$"
        />
      </p>

      <p>
        <b>a) Balanced Equation:</b>
      </p>
      <chemical-notation-input
        v-model="inputs.balancedA"
        dense
        :show-note="false"
        class="mb-4"
        :disabled="!allowEditing"
      />

      <p>
        <b>b) Total Ionic Equation:</b>
      </p>
      <chemical-notation-input
        v-model="inputs.totalIonicA"
        dense
        :show-note="false"
        class="mb-4"
        :disabled="!allowEditing"
      />

      <p>
        <b>c) Net Ionic Equation:</b>
      </p>
      <chemical-notation-input
        v-model="inputs.netIonicA"
        dense
        :show-note="false"
        class="mb-7"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question344',
  components: {
    ChemicalNotationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        balancedA: null,
        totalIonicA: null,
        netIonicA: null,
      },
    };
  },
};
</script>
